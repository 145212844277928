.App {

}

.body {
  /* mobile viewport bug fix */
  /* min-height: -webkit-fill-available; */
   padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  pointer-events: none;
  transition: all .3s ease;
}

.fade-in {
  background-color: rgba(0, 0, 0, 1);
}


.fade-out {
  background-color: rgba(0, 0, 0, 0);
}
