
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100vw;
    flex-direction: column;
    min-height: -webkit-fill-available;
}

.text {
    color: white;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all ease 0.5s;
    width: 100vw;
    background: transparent linear-gradient(180deg, #1B304D00 0%, #1B304D99 100%) 0% 0% no-repeat padding-box;
    padding-bottom: 50px;
}

.bottomContainer p {
    text-align: center;
}

.arrows:first-child {
    margin-right: 20px;
}