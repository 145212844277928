body {
  margin: 0;
  /* background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(153,153,153,1) 100%); */
  /* background-color: white; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  color: #18355d;
  font-family: museo;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Museo;
  src: url("Museo300-Regular.otf") format("opentype");
}

@font-face {
  font-family: Museo;
  src: url("Museo300-Regular.otf") format("opentype");
}

@font-face {
  font-family: MuseoBold;
  src: url("MuseoSansRounded-700-webfont.woff2") format("opentype");
}

@font-face {
  font-family: arialbd;
  src: url("arialbd.ttf") format("opentype");
}

@font-face {
  font-family: EmojiOneColor;
  src: url("EmojiOneColor.otf") format("opentype");
}

canvas {
  cursor: grab;
}

canvas:active {
  cursor: grabbing;
}

.videoPlayer {
  object-fit: cover;
  z-index: -2;
}

.videoPlayerRaw {
  object-fit: cover;
  z-index: -2;
  width: 100%;
  height: 100%;
  position: fixed;
}

.videoPlayerTransition {
  position: fixed;
  object-fit: cover;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

video {
  background-color: transparent;
}

.dot {
  width: 60px;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.inner-dot {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  width: 25%;
  height: 25%;
  position: absolute;
}

.outer-dot {
  border: solid 3px rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  animation: outer-circle-animation 1s;
  animation-iteration-count: infinite;
}

@keyframes outer-circle-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* PRoduct UI */

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotateZ(135deg);
  border-radius: 2px;
}

.arrows {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #ebebeb;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  transition: transform 0.15s ease;
}

.arrows img {
  width: 35px;
}

.arrows:hover {
  transform: scale(1.2);
  transition: transform 0.15s ease;
}

.text {
  color: white;
  font-family: MuseoBold;
}

.pointer {
  transition: transform 0.15s ease;
  cursor: pointer;
  pointer-events: all;
}

.highlighted {
  position: absolute;
  content: '✓';
  color: white;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
}

.pointer:hover {
  transform: scale(1.1);
  transition: transform 0.15s ease;
}

h1,
h2,
h3,
h4,
h5 {
  text-align: center;
  font-family: MuseoBold !important;
  color: white;
  margin-bottom: 0 !important;
}

.loading-screen {
  background-color: #1B304D;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  user-select: none;
  pointer-events: none;
}

.email-container {
  transition: transform ease 1s;
  width: 400px;
  height: auto;
  background-color: rgb(0, 177, 217); 
  border-radius: 15px;
}

.email-container input, .email-container textarea {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #1B304D;
  border: none;
  font-family: MuseoBold !important;
  padding: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.email-container input:focus-visible {
  border: none;
}

.submit-button {
  background-color: transparent;
  border: 3px solid #EBEBEB;
  font-family: MuseoBold !important;
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  background-color: #00b1d9;
}

.error {
  border: 3px solid #aa2e2e !important;
}

.color-swatch {
  width: 20px;
  height: 70px;
  border-radius: 8px;
  transition: all ease .5s;
  pointer-events: all;
}

.white-svg {
  filter: invert(99%) sepia(12%) saturate(1%) hue-rotate(266deg) brightness(115%) contrast(100%);;
}
