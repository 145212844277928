.topContainer {
    position: fixed;
    z-index: 11;
    top: 0;
    width: 100vw;
    user-select: none;
}

.bottomContainer {
    position: fixed;
    z-index: 11;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform ease 0.5s;
    width: 100vw;
    height: 25vh;
    max-height: 250px;
    background: transparent linear-gradient(180deg, #1B304D00 0%, #1B304D99 100%) 0% 0% no-repeat padding-box;
    user-select: none;
}

.infoContainer {
    position: fixed;
    z-index: 9;
    right: 0;
    width: 350px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    pointer-events: none;
}

.dragInfoBox {
    position: absolute;
    top: calc(50vh - 50px / 2);
    left: calc(50vw - 270px / 2);
    background: #1b304d60 0% 0% no-repeat padding-box;
    border-radius: 27px;
    z-index: 6;
    width: 270px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.5s;
    pointer-events: none;
}

.collapseButton {
    z-index: 9;
    position: fixed;
    top: calc((100vh - 100px) / 2);
    right: 0;
    height: 50px;
    width: 50px;
    background-color: #FFFFFF44;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.backBtn {
    pointer-events: all;
    border: solid 2px white;
    border-radius: 27px;
    padding: 0;
    margin: 15px;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}